let appID = "local.64f5ccd60d6466.31495126"; // Dev
// let domainMain = "bitrix24-middle-api.sandbox.linups.online"; // Dev
let domainMain = '3plportal-test.unilever-rus.ru';
if (window.location.hostname === "3plportal.unilever-rus.ru") {
  appID = "local.64ccf097689e10.26226205"; // Prod
  domainMain = "3plportal.unilever-rus.ru"; // Prod
}


let dockBookingListId = 71; // DEV
let shipmentList = 73; // DEV
let logList = 81;

let fieldsLogs = {
  PROPERTY_721: { name: "Номер рейса", id: 731, code: "PROPERTY_731" },
  PROPERTY_742: { name: "Создал (Email)", id: 741, code: "PROPERTY_741" },
  PROPERTY_722: { name: "Изменил (Email)", id: 732, code: "PROPERTY_732" },
  PROPERTY_723: { name: "Дата последнего изменения", id: 733, code: "PROPERTY_733" },
  PROPERTY_724: { name: "Время последнего изменения", id: 734, code: "PROPERTY_734" },
}

let fieldsShipment = {
  PROPERTY_449: { name: "Обьем накладной", id: 481, code: "PROPERTY_481" },
  PROPERTY_451: { name: "Вес накладной", id: 483, code: "PROPERTY_483" },
  PROPERTY_453: {
    name: "Количество паллет по накладной",
    id: 489,
    code: "PROPERTY_489",
  },
  PROPERTY_455: {
    name: "Количество коробов по накладной",
    id: 487,
    code: "PROPERTY_487",
  },
  PROPERTY_461: { name: "Номер накладной", id: 485, code: "PROPERTY_485" },
  PROPERTY_617: { name: "Warehouse ID", id: 619, code: "PROPERTY_619" },
  PROPERTY_725: { name: "Фактическая дата прибытия (COD)", id: 735, code: "PROPERTY_735" },
  PROPERTY_726: { name: "Фактическое время прибытия (COD)", id: 736, code: "PROPERTY_736" },
  PROPERTY_727: { name: "Фактическая дата убытия (Actual Departure)", id: 737, code: "PROPERTY_737" },
  PROPERTY_728: { name: "Фактическое время убытия (Actual Departure)", id: 738, code: "PROPERTY_738" },
  PROPERTY_729: { name: "Комментарии (Remark COD)", id: 739, code: "PROPERTY_739" },
  PROPERTY_730: { name: "Стоимость рейса", id: 740, code: "PROPERTY_740" },
  PROPERTY_627: { name: "Точка доставки", id: 625, code: "PROPERTY_625" },
};

let fields = {
  PROPERTY_341: { name: "Номер рейса", id: 668, code: "PROPERTY_668" }, // Done
  PROPERTY_589: { name: "Номер накладной", id: 670, code: "PROPERTY_670" }, // Done
  PROPERTY_291: { name: "Склад активности", id: 671, code: "PROPERTY_671" }, // Done
  PROPERTY_353: { name: "Точка доставки", id: 672, code: "PROPERTY_672" }, // Done
  PROPERTY_369: { name: "Общий объем", id: 673, code: "PROPERTY_673" }, // Done
  PROPERTY_367: { name: "Общий вес", id: 675, code: "PROPERTY_675" }, // Done
  PROPERTY_365: {
    name: "Общее количество паллет",
    id: 674,
    code: "PROPERTY_674",
  }, // Done
  PROPERTY_363: {
    name: "Общее количество коробов",
    id: 676,
    code: "PROPERTY_676",
  }, // Done
  PROPERTY_281: { name: "Appointment Date", id: 677, code: "PROPERTY_677" }, // Done
  PROPERTY_439: { name: "Shipment Start Date", id: 678, code: "PROPERTY_678" }, // Done
  PROPERTY_323: { name: "Appointment Time", id: 679, code: "PROPERTY_679" }, // Done
  PROPERTY_355: {
    name: "Наименование точки доставки",
    id: 680,
    code: "PROPERTY_680",
  }, // Done
  PROPERTY_357: {
    name: "Область точки доставки",
    id: 681,
    code: "PROPERTY_681",
  }, // Done
  PROPERTY_359: { name: "Город точки доставки", id: 682, code: "PROPERTY_682" }, // Done
  PROPERTY_361: { name: "Адрес точки доставки", id: 683, code: "PROPERTY_683" }, // Done
  PROPERTY_373: { name: "Точка отгрузки", id: 684, code: "PROPERTY_684" }, // Done
  PROPERTY_375: { name: "Точка доставки", id: 685, code: "PROPERTY_685" }, // Done
  PROPERTY_339: { name: "Тип трака", id: 686, code: "PROPERTY_686" }, // Done
  PROPERTY_377: { name: "Код перевозчика", id: 687, code: "PROPERTY_687" }, // Done
  PROPERTY_379: {
    name: "Наименование перевозчика",
    id: 688,
    code: "PROPERTY_688",
  }, // Done
  PROPERTY_317: { name: "Количество слотов", id: 689, code: "PROPERTY_689" }, // Done
  PROPERTY_419: {
    name: "Количество слотов (ручной)",
    id: 690,
    code: "PROPERTY_690",
  }, // Done
  PROPERTY_381: { name: "Тип процесса", id: 691, code: "PROPERTY_691" }, // Done
  PROPERTY_285: { name: "Док", id: 692, code: "PROPERTY_692" }, // Done
  PROPERTY_289: { name: "Группа доступа", id: 693, code: "PROPERTY_693" }, // Done
  PROPERTY_311: {
    name: "Статус",
    id: 694,
    code: "PROPERTY_694",
    options: {
      367: { id: 464, code: "464", title: "Pending" },
      369: { id: 465, code: "465", title: "Infeasible" },
      371: { id: 466, code: "466", title: "Confirmed" },
    },
  }, // Done
  PROPERTY_325: { name: "Appointment End Time", id: 695, code: "PROPERTY_695" }, // Done
  PROPERTY_385: {
    name: "Загрузка/выгрузка",
    id: 696,
    code: "PROPERTY_696",
    options: {
      399: { id: 467, code: "467", title: "Drop" },
      401: { id: 468, code: "468", title: "Pick" },
    },
  }, // Done
  PROPERTY_387: {
    name: "В работе",
    id: 697,
    code: "PROPERTY_697",
    options: {
      403: { id: 469, code: "469", title: "Y" },
      405: { id: 470, code: "470", title: "N" },
    },
  }, // Done
  PROPERTY_391: { name: "ФИО водителя", id: 698, code: "PROPERTY_698" }, // Done
  PROPERTY_393: { name: "Номер паспорта", id: 699, code: "PROPERTY_699" }, // Done
  PROPERTY_395: {
    name: "Номер телефона водителя",
    id: 700,
    code: "PROPERTY_700",
  }, // Done
  PROPERTY_397: { name: "Driver Tax Number", id: 719, code: "PROPERTY_719" }, // Done
  PROPERTY_399: {
    name: "У трака есть прицеп?",
    id: 701,
    code: "PROPERTY_701",
    options: {
      407: { id: 471, code: "471", title: "Y" },
      409: { id: 472, code: "472", title: "N" },
    },
  }, // Done
  PROPERTY_425: { name: "Номер прицепа", id: 702, code: "PROPERTY_702" }, // Done
  PROPERTY_401: { name: "Марка машины", id: 703, code: "PROPERTY_703" }, // Done
  PROPERTY_403: { name: "Номер машины", id: 704, code: "PROPERTY_704" }, // Done
  PROPERTY_405: { name: "Truck Ownership Type", id: 705, code: "PROPERTY_705" }, // Done
  PROPERTY_407: { name: "Remark", id: 706, code: "PROPERTY_706" }, // Done
  PROPERTY_409: {
    name: "Является экспедитором",
    id: 707,
    code: "PROPERTY_707",
    options: {
      411: { id: 473, code: "473", title: "Y" },
      413: { id: 474, code: "474", title: "N" },
    },
  }, // Done
  PROPERTY_411: { name: "ТК - экспедитор", id: 708, code: "PROPERTY_708" }, // Done
  PROPERTY_413: {
    name: "Адрес ТК - экспедитора",
    id: 709,
    code: "PROPERTY_709",
  }, // Done
  PROPERTY_415: { name: "ИНН ТК - экспедитора", id: 710, code: "PROPERTY_710" }, // Done
  PROPERTY_417: {
    name: "Телефон ТК - экспедитора",
    id: 711,
    code: "PROPERTY_711",
  }, // Done
  PROPERTY_421: {
    name: "Ручной appointment",
    id: 712,
    code: "PROPERTY_712",
    options: {
      417: { id: 475, code: "475", title: "Y" },
      415: { id: 476, code: "476", title: "N" },
    },
  }, // Done
  PROPERTY_477: { name: "LDD Date", id: 713, code: "PROPERTY_713" }, // Done
  PROPERTY_665: { name: "LDD Time", id: 714, code: "PROPERTY_714" }, // Done
  PROPERTY_609: {
    name: "Самая последняя точка доставки (Details)",
    id: 715,
    code: "PROPERTY_715",
  }, // Done
  PROPERTY_613: {
    name: "Номер накладной (Ручной Appointment)",
    id: 717,
    code: "PROPERTY_717",
  }, // Done
  PROPERTY_607: {
    name: "Самый первый склад отгрузки (Details)",
    id: 718,
    code: "PROPERTY_718",
  }, // Done
  PROPERTY_621: {
    name: "Дата для запросов букинга (техническая, НЕ ТРОГАТЬ)",
    id: 716,
    code: "PROPERTY_716",
  },
};

if (window.location.hostname === "3plportal.unilever-rus.ru") {
  dockBookingListId = 47; // Prod
  shipmentList = 67; // Prod
  logList = 80; // Prod
  fieldsLogs = {
    PROPERTY_721: { name: "Номер рейса", id: 721, code: "PROPERTY_721" },
    PROPERTY_742: { name: "Создал (Email)", id: 742, code: "PROPERTY_742" },
    PROPERTY_722: { name: "Изменил (Email)", id: 722, code: "PROPERTY_722" },
    PROPERTY_723: { name: "Дата последнего изменения", id: 723, code: "PROPERTY_723" },
    PROPERTY_724: { name: "Время последнего изменения", id: 724, code: "PROPERTY_724" },
  }
  fieldsShipment = {
    PROPERTY_449: { name: "Обьем накладной", id: 449, code: "PROPERTY_449" },
    PROPERTY_451: { name: "Вес накладной", id: 451, code: "PROPERTY_451" },
    PROPERTY_453: {
      name: "Количество паллет по накладной",
      id: 453,
      code: "PROPERTY_453",
    },
    PROPERTY_455: {
      name: "Количество коробов по накладной",
      id: 455,
      code: "PROPERTY_455",
    },
    PROPERTY_461: { name: "Номер накладной", id: 461, code: "PROPERTY_461" },
    PROPERTY_617: { name: "Warehouse ID", id: 617, code: "PROPERTY_617" },
    PROPERTY_725: { name: "Фактическая дата прибытия (COD)", id: 725, code: "PROPERTY_725" },
    PROPERTY_726: { name: "Фактическое время прибытия (COD)", id: 726, code: "PROPERTY_726" },
    PROPERTY_727: { name: "Фактическая дата убытия (Actual Departure)", id: 727, code: "PROPERTY_727" },
    PROPERTY_728: { name: "Фактическое время убытия (Actual Departure)", id: 728, code: "PROPERTY_728" },
    PROPERTY_729: { name: "Комментарии (Remark COD)", id: 729, code: "PROPERTY_729" },
    PROPERTY_730: { name: "Стоимость рейса", id: 730, code: "PROPERTY_730" },
    PROPERTY_627: { name: "Точка доставки", id: 627, code: "PROPERTY_627" },
  };
  fields = {
    PROPERTY_341: { name: "Номер рейса", id: 341, code: "PROPERTY_341" }, // Done
    PROPERTY_589: { name: "Номер накладной", id: 589, code: "PROPERTY_589" }, // Done
    PROPERTY_291: { name: "Склад активности", id: 291, code: "PROPERTY_291" }, // Done
    PROPERTY_353: { name: "Точка доставки", id: 353, code: "PROPERTY_353" }, // Done
    PROPERTY_369: { name: "Общий объем", id: 369, code: "PROPERTY_369" }, // Done
    PROPERTY_367: { name: "Общий вес", id: 367, code: "PROPERTY_367" }, // Done
    PROPERTY_365: {
      name: "Общее количество паллет",
      id: 365,
      code: "PROPERTY_365",
    }, // Done
    PROPERTY_363: {
      name: "Общее количество коробов",
      id: 363,
      code: "PROPERTY_363",
    }, // Done
    PROPERTY_281: { name: "Appointment Date", id: 281, code: "PROPERTY_281" }, // Done
    PROPERTY_439: {
      name: "Shipment Start Date",
      id: 439,
      code: "PROPERTY_439",
    }, // Done
    PROPERTY_323: { name: "Appointment Time", id: 323, code: "PROPERTY_323" }, // Done
    PROPERTY_355: {
      name: "Наименование точки доставки",
      id: 355,
      code: "PROPERTY_355",
    }, // Done
    PROPERTY_357: {
      name: "Область точки доставки",
      id: 357,
      code: "PROPERTY_357",
    }, // Done
    PROPERTY_359: {
      name: "Город точки доставки",
      id: 359,
      code: "PROPERTY_359",
    }, // Done
    PROPERTY_361: {
      name: "Адрес точки доставки",
      id: 361,
      code: "PROPERTY_361",
    }, // Done
    PROPERTY_373: { name: "Точка отгрузки", id: 373, code: "PROPERTY_373" }, // Done
    PROPERTY_375: { name: "Точка доставки", id: 375, code: "PROPERTY_375" }, // Done
    PROPERTY_339: { name: "Тип трака", id: 339, code: "PROPERTY_339" }, // Done
    PROPERTY_377: { name: "Код перевозчика", id: 377, code: "PROPERTY_377" }, // Done
    PROPERTY_379: {
      name: "Наименование перевозчика",
      id: 379,
      code: "PROPERTY_379",
    }, // Done
    PROPERTY_317: { name: "Количество слотов", id: 317, code: "PROPERTY_317" }, // Done
    PROPERTY_419: {
      name: "Количество слотов (ручной)",
      id: 419,
      code: "PROPERTY_419",
    }, // Done
    PROPERTY_381: { name: "Тип процесса", id: 381, code: "PROPERTY_381" }, // Done
    PROPERTY_285: { name: "Док", id: 285, code: "PROPERTY_285" }, // Done
    PROPERTY_289: { name: "Группа доступа", id: 289, code: "PROPERTY_289" }, // Done
    PROPERTY_311: {
      name: "Статус",
      id: 311,
      code: "PROPERTY_311",
      options: {
        367: { id: 367, code: "367", title: "Pending" },
        369: { id: 369, code: "369", title: "Infeasible" },
        371: { id: 371, code: "371", title: "Confirmed" },
      },
    }, // Done
    PROPERTY_325: {
      name: "Appointment End Time",
      id: 325,
      code: "PROPERTY_325",
    }, // Done
    PROPERTY_385: {
      name: "Загрузка/выгрузка",
      id: 385,
      code: "PROPERTY_385",
      options: {
        399: { id: 399, code: "399", title: "Drop" },
        401: { id: 401, code: "401", title: "Pick" },
      },
    }, // Done
    PROPERTY_387: {
      name: "В работе",
      id: 387,
      code: "PROPERTY_387",
      options: {
        403: { id: 403, code: "403", title: "Y" },
        405: { id: 405, code: "405", title: "N" },
      },
    }, // Done
    PROPERTY_391: { name: "ФИО водителя", id: 391, code: "PROPERTY_391" }, // Done
    PROPERTY_393: { name: "Номер паспорта", id: 393, code: "PROPERTY_393" }, // Done
    PROPERTY_395: {
      name: "Номер телефона водителя",
      id: 395,
      code: "PROPERTY_395",
    }, // Done
    PROPERTY_397: { name: "Driver Tax Number", id: 397, code: "PROPERTY_397" }, // Done
    PROPERTY_399: {
      name: "У трака есть прицеп?",
      id: 399,
      code: "PROPERTY_399",
      options: {
        407: { id: 407, code: "407", title: "Y" },
        409: { id: 409, code: "409", title: "N" },
      },
    }, // Done
    PROPERTY_425: { name: "Номер прицепа", id: 425, code: "PROPERTY_425" }, // Done
    PROPERTY_401: { name: "Марка машины", id: 401, code: "PROPERTY_401" }, // Done
    PROPERTY_403: { name: "Номер машины", id: 403, code: "PROPERTY_403" }, // Done
    PROPERTY_405: {
      name: "Truck Ownership Type",
      id: 405,
      code: "PROPERTY_405",
    }, // Done
    PROPERTY_407: { name: "Remark", id: 407, code: "PROPERTY_407" }, // Done
    PROPERTY_409: {
      name: "Является экспедитором",
      id: 409,
      code: "PROPERTY_409",
      options: {
        411: { id: 411, code: "411", title: "Y" },
        413: { id: 413, code: "413", title: "N" },
      },
    }, // Done
    PROPERTY_411: { name: "ТК - экспедитор", id: 411, code: "PROPERTY_411" }, // Done
    PROPERTY_413: {
      name: "Адрес ТК - экспедитора",
      id: 413,
      code: "PROPERTY_413",
    }, // Done
    PROPERTY_415: {
      name: "ИНН ТК - экспедитора",
      id: 415,
      code: "PROPERTY_415",
    }, // Done
    PROPERTY_417: {
      name: "Телефон ТК - экспедитора",
      id: 417,
      code: "PROPERTY_417",
    }, // Done
    PROPERTY_421: {
      name: "Ручной appointment",
      id: 421,
      code: "PROPERTY_421",
      options: {
        415: { id: 415, code: "415", title: "N" },
        417: { id: 417, code: "417", title: "Y" },
      },
    }, // Done
    PROPERTY_477: { name: "LDD Date", id: 477, code: "PROPERTY_477" }, // Done
    PROPERTY_665: { name: "LDD Time", id: 665, code: "PROPERTY_665" }, // Done
    PROPERTY_609: {
      name: "Самая последняя точка доставки (Details)",
      id: 609,
      code: "PROPERTY_609",
    }, // Done
    PROPERTY_613: {
      name: "Номер накладной (Ручной Appointment)",
      id: 613,
      code: "PROPERTY_613",
    }, // Done
    PROPERTY_607: {
      name: "Самый первый склад отгрузки (Details)",
      id: 607,
      code: "PROPERTY_607",
    }, // Done
    PROPERTY_621: {
      name: "Дата для запросов букинга (техническая, НЕ ТРОГАТЬ)",
      id: 621,
      code: "PROPERTY_621",
    },
    // PROPERTY_473: { name: "Время доставки", id: 473, code: "PROPERTY_473" },
  };
}

export {
  appID,
  dockBookingListId,
  domainMain,
  fields,
  fieldsShipment,
  shipmentList,
  logList,
  fieldsLogs
};

export const isLocalhost = window.location.host == ( 'localhost:3000' || 'localhost:3001' ) && true || false;

export const paramsLocalhostApi = 'ss=f786dfbr3k';

export const domain = "3pl-bitrix-prod.unilever-rus.ru";

export const timeSessionApp = 1000 * 60 * 60 * 8;

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getValueProperty = (object, propertyID) => {
  if (!object["PROPERTY_" + propertyID]) {
    return "";
  }
  let result = Object.values(object["PROPERTY_" + propertyID]);
  if (result.length == 1) {
    result = result[0];
  }
  return result;
};

export const redirectAuth = () => {
  if (!getWithExpiry("authApp3pl")) {
    window.location.href = "https://" + domain +
      "/oauth/authorize/?client_id=" + appID;
  }
};


export const f = x => ( (x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0) );
