import './assets/css/old.css';
// import './assets/css/style.css';
// import './assets/css/custom.css';
// import "./assets/css/insctructions.css";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthApp from './modules/authApp';
import Booking from './pages/booking';
import FixDate from './modules/fixDate';
import Header from './layouts/header';
import Footer from './layouts/footer';
// import Services from "./carriers/pages/services";
// import PO from "./carriers/pages/po";
// import POItem from "./carriers/pages/poItem";
// import Requests from "./carriers/pages/requests";
// import RequestItem from "./carriers/pages/requestItem";
// import KPI from './carriers/pages/kpi';
// import Insctructions from "./carriers/pages/insctructions";
// import Add from "./carriers/pages/add";
// import Contacts from "./carriers/pages/contacts";
import Cod from './pages/cod';
import CodAdd from './pages/cod/add';


function App() {

  return (
    <Router>
      <AuthApp />
      <div className='wrapper _loaded' id="dock-booking">
        <Header />
        <Routes>
          <Route path="/dockBooking" element={<Booking />} />
          {/* <Route exact path="/" element={<Services />} /> */}
          <Route exact path="/" element={<Booking />} />
          <Route path="/?*" element={<Booking />} />
          <Route path="/fixdate" element={<FixDate />} />
          {/* <Route path="/po" element={<PO />} />
          <Route path="/poItem/:id" element={<POItem />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/requestItem/:id" element={<RequestItem />} />
          <Route path="/kpi" element={<KPI />} />
          <Route path="/insctructions" element={<Insctructions />} />
          <Route path="/add/:id?" element={<Add />} />
          <Route path="/contacts" element={<Contacts />} /> */}
          <Route path="/cod" element={<Cod />} />
          <Route path="/cod/add" element={<CodAdd />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
