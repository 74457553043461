import {call, put, takeEvery, all, take, takeLatest} from "redux-saga/effects";

import { 
    searchWarehouseRequest, 
    searchBookingRequest, 
    searchDestinationRequest, 
    getExcelRequest
} from "./api";

import { 
    exportFormSearchWarehouse,
    exportFormSearchWarehouseSuccess,
    exportFormSearchWarehouseFailure,
    exportFormSearchWarehouseFind,
    exportFormSearchBooking,
    exportFormSearchBookingSuccess,
    exportFormSearchBookingFailure,
    exportFormSearchBookingFind,
    exportFormSearchDestination,
    exportFormSearchDestinationSuccess,
    exportFormSearchDestinationFailure,
    exportFormSearchDestinationFind,
    getExcel,
    getExcelSuccess,
    getExcelFailure,
    getExcelIdle
} from './actions';

function* exportFormSearchWarehouseSaga(action) {
    try {

        const { payload } = action;

        let response;
        
        // if(payload.list){
        //     response = yield all(payload.list.map(item => call(searchWarehouseRequest, { code: item })))
        // } else {
            response = yield call(searchWarehouseRequest, payload);
        // }

        // console.log(response);

        let selectOptions = [];

        if(payload.list){
            // if(response && response.length > 0){
            //     response.map(item => {
            //         if(item.result && item.result.length > 0){
            //             selectOptions.push({
            //                 value: item.result[0].ID,
            //                 label: item.result[0].NAME
            //             }) 
            //         }
            //     })
            // }
            if(response && response.result && response.result.length > 0){
                response.result.map(item => {
    
                    selectOptions.push({
                        value: item.ID,
                        label: item.NAME
                    })
    
                })
            }
        } else {
            if(response && response.result && response.result.length > 0){
                response.result.map(item => {

                    selectOptions.push({
                        value: item.ID,
                        label: item.NAME
                    })

                })
            }
        }

        if(payload.list){
            yield put(exportFormSearchWarehouseFind(selectOptions));
        } else {
            yield put(exportFormSearchWarehouseSuccess(selectOptions));
        }

    } catch (error) {

        yield put(exportFormSearchWarehouseFailure(error));

    }
}

function* exportFormSearchBookingSaga(action) {
    try {

        const { payload } = action;

        let response;

        // if(payload.list){
        //     response = yield all(payload.list.map(item => call(searchBookingRequest, { code: item })))
        // } else {
            response = yield call(searchBookingRequest, payload);
        // }

        // console.log(response);

        let selectOptions = [];

        if(payload.list){
            // if(response && response.length > 0){
            //     response.map(item => {
            //         if(item.result && item.result.length > 0){
            //             selectOptions.push({
            //                 value: item.result[0].ID,
            //                 label: item.result[0].NAME
            //             }) 
            //         }
            //     })
            // }
            if(response && response.result && response.result.length > 0){
                response.result.map(item => {
    
    
                    if(payload.type && payload.type == 'cod'){
                        selectOptions.push({
                            value: Object.values(item.PROPERTY_341)[0],
                            label: item.NAME
                        })
                    } else {
                        selectOptions.push({
                            value: item.ID,
                            label: item.NAME
                        })
                    }
    
                })
            }
        } else {
            if(response && response.result && response.result.length > 0){
                response.result.map(item => {
    
                    if(payload.type && payload.type == 'cod'){
                        selectOptions.push({
                            value: Object.values(item.PROPERTY_341)[0],
                            label: item.NAME
                        })
                    } else {
                        selectOptions.push({
                            value: item.ID,
                            label: item.NAME
                        })
                    }
    
                })
            }
        }

        if(payload.list){
            yield put(exportFormSearchBookingFind(selectOptions));
        } else {
            yield put(exportFormSearchBookingSuccess(selectOptions));
        }        

    } catch (error) {

        yield put(exportFormSearchBookingFailure(error));

    }
}

function* exportFormSearchDestinationSaga(action) {
    try {

        const { payload } = action;

        let response;

        // if(payload.list){
        //     response = yield all(payload.list.map(item => call(searchDestinationRequest, { code: item })))
        // } else {
            response = yield call(searchDestinationRequest, payload);
        // }

        let selectOptions = [];

        if(payload.list){
            // if(response && response.length > 0){
            //     response.map(item => {
            //         if(item.result && item.result.length > 0){
            //             selectOptions.push({
            //                 value: item.result[0].ID,
            //                 label: ( item.result[0].PROPERTY_431 && Object.values(item.result[0].PROPERTY_431)[0] || '' ) + ' (' + item.result[0].NAME + ')'
            //             }) 
            //         }
            //     })
            // }
            if(response && response.result && response.result.length > 0){
                response.result.map(item => {
    
                    selectOptions.push({
                        value: item.ID,
                        label: ( item.PROPERTY_431 && Object.values(item.PROPERTY_431)[0] || '' ) + ' (' + item.NAME + ')'
                    })
    
                })
            }
        } else {
            if(response && response.result && response.result.length > 0){
                response.result.map(item => {
    
                    selectOptions.push({
                        value: item.ID,
                        label: ( item.PROPERTY_431 && Object.values(item.PROPERTY_431)[0] || '' ) + ' (' + item.NAME + ')'
                    })
    
                })
            }
        }

        if(payload.list){
            yield put(exportFormSearchDestinationFind(selectOptions));
        } else {
            yield put(exportFormSearchDestinationSuccess(selectOptions));
        } 

    } catch (error) {

        yield put(exportFormSearchDestinationFailure(error));

    }
}

function* getExcelSaga(action) {
    try {

        const { payload } = action;

        let response = yield call(getExcelRequest, payload);

        yield put(getExcelSuccess(response));

    } catch (error) {

        yield put(getExcelFailure(error));

    }
}

export function* exportFormWatcher() {
    yield takeLatest(exportFormSearchWarehouse.toString(), exportFormSearchWarehouseSaga);
    yield takeLatest(exportFormSearchBooking.toString(), exportFormSearchBookingSaga);
    yield takeLatest(exportFormSearchDestination.toString(), exportFormSearchDestinationSaga);
    yield takeLatest(getExcel.toString(), getExcelSaga);
}