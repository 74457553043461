import { combineReducers } from "redux";
import { authApp } from "./modules/authApp/reducer";
import { initData } from "./modules/initData/reducer";
import { booking } from "./modules/booking/reducer";
import { cod } from "./modules/cod/reducer";
import { exportForm } from './modules/export/reducer';

export default combineReducers({
  authApp,
  booking,
  initData,
  exportForm,
  cod
});
