import * as React from "react";
import logo from '../../assets/img/icons/logo.svg';
import iconAvatar from '../../assets/img/icons/Avatar.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

const Header = () => {

  const dataUser = useSelector(state => state.authApp.init);
  const typeUser = useSelector(state => state.authApp.typeUser);
  const location = useLocation();
  const { pathname } = location;

  React.useEffect(() => {
    // console.log('dataUser: ', dataUser);
    // console.log('typeUser: ', typeUser);
  }, [dataUser, typeUser]);

  // React.useEffect(() => {
  //   const mainWrapperSite = document.querySelector(".wrapper._loaded");
  //   if (mainWrapperSite) {
  //     const mainWrapperSiteID = cx(
  //       (pathname.indexOf("/po") !== -1 && pathname.indexOf("/poItem/") === -1) && "po",
  //       pathname.indexOf("/poItem/") !== -1 && "po-application",
  //       pathname.indexOf("/requests") !== -1 && "requests-doc-2",
  //       pathname.indexOf("/requestItem/") !== -1 &&
  //         "requests-doc-security",
  //       pathname.indexOf('/cod') == -1 && pathname.indexOf("/add") !== -1 && "requests-doc-transport",
  //       pathname.indexOf("/dockBooking") !== -1 && "dock-booking",
  //     );
  //     mainWrapperSite.id = mainWrapperSiteID;
  //   }
  // }, [location])

  React.useEffect(() => {
    if (window.location.href.indexOf("#posts") !== -1) {
      const blockPosts = document.querySelector("#posts");
      if (blockPosts) {
        blockPosts.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }, []);

  const checkPostsLink = (e) => {
    e.preventDefault();
    if (window.location.pathname === "/") {
      const blockPosts = document.querySelector("#posts");
      if (blockPosts) {
        blockPosts.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    } else {
      window.location.href = "/#posts";
    }
  }
  return <header className="header">
    <div className="header__content container">
      <Link to={"/"} className="logo header__logo"> <img src={logo} alt="logo" />
        <div className="logo__title">Единый ресурс для осуществления коммуникации с&nbsp;перевозчиками</div>
      </Link>
      <nav className="menu header__menu">
        <ul className="menu__list">
          {/* <li className="menu__item"><Link className="menu__link" to={"/"}>Сервисы</Link></li> */}
          {/* <li className="menu__item"><Link className="menu__link" to={"/kpi"}>KPI</Link></li> */}
          <li className="menu__item"><Link className="menu__link" to={"/dockBooking"}>Dock booking</Link></li>
          {/* {window.location.search.indexOf('mode=COD') >= 0 && ( */}
            <li className="menu__item"><Link className="menu__link" to={"/cod"}>COD</Link></li>
          {/* )} */}
          {/* <li className="menu__item"><a href="#" className="menu__link" onClick={(e) => checkPostsLink(e)}>Полезное</a></li> */}
        </ul>
      </nav>
      <div className="login header__login">
        <div className="login__wrap">
          {dataUser && (
            <>
              <span className="login__title">{dataUser.last_name} {dataUser.name}</span>
              {dataUser.group === "user_ul" && (
                <span className="login__company">Сотрудник Unilever</span>
              ) || (
                <span className="login__company">Партнёр</span>
              )}
            </>
          )}
        {/*<span className="login__number">{currentUser['warehouses']}</span> */}
        </div>
        <img src={`${iconAvatar}`} alt="avatar" className="login__icon" />
      </div>
    </div>
  </header>;
};
export default Header;
