import { fork } from "redux-saga/effects";
import { handleAuthAppWatcher } from "./modules/authApp/saga";
import { getInitDataWatcher } from './modules/initData/saga';
import { bookingWatcher } from "./modules/booking/saga";
import { codWatcher } from "./modules/cod/saga";
import { exportFormWatcher } from './modules/export/saga';
import { handleFixDateWatcher } from './modules/fixDate/saga';

export default function* rootSaga() {
  yield fork(handleAuthAppWatcher);
  yield fork(bookingWatcher);
  yield fork(codWatcher);
  yield fork(getInitDataWatcher);
  yield fork(exportFormWatcher);
  yield fork(handleFixDateWatcher);
}
