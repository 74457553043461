import {call, put, takeEvery, all} from "redux-saga/effects";

import { 
    getListWarehousesReguest, 
    getListDocksReguest, 
    getFieldsReguest,
    getListTimeReguest,
    getListEquipmentsGroupsReguest,
    getListTypeTrucksReguest,
    getListUsersReguest,
    getListLocationsReguest,
    getListProviderReguest
} from "./api";

import {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
    setActiveWarehouses,
} from "./actions";
import { getListBookingReguest } from "../booking/api";
import { dockBookingListId } from "../../constants/config";

function* getInitDataSaga(action) {
    try {

        // const listUsersProdider = null;
        // const fieldsWarehouses = null;
        // const fieldsDocks = null;
        // const fieldsBookings = null;

        const [
            warehouses, 
            docks1,
            docks2,
            docks3, 
            // bookings, 
            time, 
            fieldsWarehouses, 
            fieldsDocks,
            fieldsBookings,
            listEquipmentsGroups,
            listTypeTrucks1,
            listTypeTrucks2,
            listTypeTrucks3,
            listUsersProdider,
            listLocations1,
            listLocations2,
            listProvider1,
            listProvider2,
            listProvider3
        ] = yield all([
            call(getListWarehousesReguest),
            call(getListDocksReguest, { start: 0 }),
            call(getListDocksReguest, { start: 50 }),
            call(getListDocksReguest, { start: 100 }),
            // call(getListBookingReguest, { idWarehouse: null, dateBookings: '2023-02-19' }),
            call(getListTimeReguest),
            call(getFieldsReguest, {
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 33,
            }),
            call(getFieldsReguest, {
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 49,
            }),
            call(getFieldsReguest, {
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": dockBookingListId,
            }),
            call(getListEquipmentsGroupsReguest),
            call(getListTypeTrucksReguest, { start: 0 }),
            call(getListTypeTrucksReguest, { start: 50 }),
            call(getListTypeTrucksReguest, { start: 100 }),
            call(getListUsersReguest, {
                'FILTER': {
                    '!UF_USR_1676292865741': 'user_ul'
                    // UF_USR_1676292865741
                }
            }),
            call(getListLocationsReguest, { start: 0 }),
            call(getListLocationsReguest, { start: 50 }),
            call(getListProviderReguest, { start: 0 }),
            call(getListProviderReguest, { start: 50 }),
            call(getListProviderReguest, { start: 100 })
        ]);

        let docksTotal = 0;
        let docksResult = [];

        if(docks1 && docks1.result){
            docksTotal = docksTotal + docks1.result.length;
            docksResult = [...docksResult, ...docks1.result];
        }
        if(docks2 && docks2.result){
            docksTotal = docksTotal + docks2.result.length;
            docksResult = [...docksResult, ...docks2.result];
        }
        if(docks3 && docks3.result){
            docksTotal = docksTotal + docks3.result.length;
            docksResult = [...docksResult, ...docks3.result];
        }

        let docks = {
            total: docksTotal,
            result: docksResult
        }

        // Типы траков

        let listTypeTrucksTotal = 0;
        let listTypeTrucksResult = [];
        
        if(listTypeTrucks1 && listTypeTrucks1.result){
            listTypeTrucksTotal = listTypeTrucksTotal + listTypeTrucks1.result.length;
            listTypeTrucksResult = [...listTypeTrucksResult, ...listTypeTrucks1.result];
        }
        if(listTypeTrucks2 && listTypeTrucks2.result){
            listTypeTrucksTotal = listTypeTrucksTotal + listTypeTrucks2.result.length
            listTypeTrucksResult = [...listTypeTrucksResult, ...listTypeTrucks2.result];
        }
        if(listTypeTrucks3 && listTypeTrucks3.result){
            listTypeTrucksTotal = listTypeTrucksTotal + listTypeTrucks3.result.length
            listTypeTrucksResult = [...listTypeTrucksResult, ...listTypeTrucks3.result];
        }

        let listTypeTrucks = {
            total: listTypeTrucksTotal,
            result: listTypeTrucksResult
        }
       
        let typeTrucksInfo = {};

        if(listTypeTrucks && listTypeTrucks.result && listTypeTrucks.result.length > 0){

            let selectOptions = [];

            listTypeTrucks.result.map(item => {

                typeTrucksInfo = {
                    ...typeTrucksInfo,
                    [item.ID]: item
                }

                if(selectOptions.findIndex(el => el.value == item.ID) < 0){
                    let labelOption = item.NAME;
                    if(item.PROPERTY_463){
                        labelOption = item.NAME + ' (' + Object.values(item.PROPERTY_463)[0] + ')';
                    }
                    selectOptions.push({
                        value: item.ID,
                        label: labelOption
                    })
                }

            })

            typeTrucksInfo = {
                typeTrucksInfo,
                selectOptions
            }
            
        } else {
            typeTrucksInfo = listTypeTrucks;
        }

        // Providers
        let listProviderTotal = 0;
        let listProviderResult = [];
        
        if(listProvider1 && listProvider1.result){
            listProviderTotal = listProviderTotal + listProvider1.result.length;
            listProviderResult = [...listProviderResult, ...listProvider1.result];
        }
        if(listProvider2 && listProvider2.result){
            listProviderTotal = listProviderTotal + listProvider2.result.length
            listProviderResult = [...listProviderResult, ...listProvider2.result];
        }
        if(listProvider3 && listProvider3.result){
            listProviderTotal = listProviderTotal + listProvider3.result.length
            listProviderResult = [...listProviderResult, ...listProvider3.result];
        }

        let listProvider = {
            total: listProviderTotal,
            result: listProviderResult
        }
       
        let listProviderInfo = {};

        if(listProvider && listProvider.result && listProvider.result.length > 0){

            let selectOptions = [];

            listProvider.result.map(item => {

                if(item.PROPERTY_457 && Object.values(item.PROPERTY_457).length > 0){
                    listProviderInfo = {
                        ...listProviderInfo,
                        [Object.values(item.PROPERTY_457)[0]]: item
                    }
                }

                if(selectOptions.findIndex(el => el.value == item.ID) < 0){
                    selectOptions.push({
                        value: item.ID,
                        label: item.NAME
                    })
                }

            })

            listProviderInfo = {
                listProviderInfo,
                selectOptions
            }
            
        } else {
            listProviderInfo = listProvider;
        }

        // Equipments

        let equipmentsInfo = {};

        if(listEquipmentsGroups && listEquipmentsGroups.result && listEquipmentsGroups.result.length > 0){

            listEquipmentsGroups.result.map(item => {

                equipmentsInfo = {
                    ...equipmentsInfo,
                    [item.ID]: item
                }

            })
            
        } else {
            equipmentsInfo = listEquipmentsGroups;
        }

        // ДОКИ

        let docksInfo = {};

        if(docks && docks.result && docks.result.length > 0){

            docks.result.map(dock => {

                docksInfo = {
                    ...docksInfo,
                    [dock.ID]: dock
                }

            })
            
        } else {
            docksInfo = docks;
        }

        // ВРЕМЯ

        let timeInfo = {};

        let timeList = [];

        if(time && time.result && time.result.length > 0){

            time.result.map(item => {
                
                timeInfo = {
                    ...timeInfo,
                    [item.ID]: item
                }

                timeList.push(item.ID);

            })

        } else {
            timeInfo = time;
        }

        // СКЛАДЫ

        let warehousesInfo = {};

        if(warehouses && warehouses.result && warehouses.result.length > 0){

            // console.log(warehouses.result);

            warehouses.result.map(warehouse => {

                let docks = Object.values(docksInfo).filter(item => {
                    if(item.PROPERTY_271){
                        return Object.values(item.PROPERTY_271)[0] == warehouse.ID;
                    }
                });

                let workTimeStart = warehouse.PROPERTY_309,
                    workTimeEnd = warehouse.PROPERTY_313,
                    cellTime = warehouse.PROPERTY_315;

                let timeWorkList = [];

                if(timeInfo && Object.values(timeInfo) && Object.values(timeInfo).length > 0){
                    let stateTime = '';
                    Object.values(timeInfo).map(time => {
                        
                        if(Object.values(workTimeStart)[0] == time.ID){
                            stateTime = 'add';
                        }

                        if(stateTime == 'add'){
                            if(Object.values(cellTime)[0] == 373){
                                if(time.NAME.indexOf(':30') < 0){
                                    timeWorkList.push(time.ID);
                                }
                            } else {
                                timeWorkList.push(time.ID);
                            }
                        }

                        if(Object.values(workTimeEnd)[0] == time.ID){
                            stateTime = '';
                        }

                    })
                }


                warehousesInfo = {
                    ...warehousesInfo,
                    [warehouse.ID]: {
                        ...warehouse,
                        docks: docks.sort((a, b) => a.SORT - b.SORT),
                        timeWorkList
                    }
                }
            })
            
        } else {
            warehousesInfo = warehouses;
        }

        // ПОЛЯ СКЛАДОВ

        let fieldsWarehousesInfo = {};

        if(fieldsWarehouses && fieldsWarehouses.result && Object.values(fieldsWarehouses.result) && Object.values(fieldsWarehouses.result).length > 0){
            fieldsWarehousesInfo = fieldsWarehouses.result;
        } else {
            fieldsWarehousesInfo = fieldsWarehouses;
        }

        // ПОЛЯ ДОКОВ

        let fieldsDocksInfo = {};

        if(fieldsDocks && fieldsDocks.result && Object.values(fieldsDocks.result) && Object.values(fieldsDocks.result).length > 0){
            fieldsDocksInfo = fieldsDocks.result;
        } else {
            fieldsDocksInfo = fieldsDocks;
        }

        // ПОЛЯ РЕЙСОВ

        let fieldsBookingsInfo = {};

        if(fieldsBookings && fieldsBookings.result && Object.values(fieldsBookings.result) && Object.values(fieldsBookings.result).length > 0){
            fieldsBookingsInfo = fieldsBookings.result;
        } else {
            fieldsBookingsInfo = fieldsBookings;
        }

        // Пользователи

        let listUsersProdiderInfo = {};

        if(listUsersProdider && listUsersProdider.result && listUsersProdider.result.length > 0){

            let listOptions = [];

            listUsersProdider.result.map(item => {
                
                if(listOptions.findIndex(el => el.value == item.UF_USR_1676292865741) < 0){
                    listOptions.push({
                        value: item.UF_USR_1676292865741,
                        label: item.NAME
                    })
                }

            })

            listUsersProdiderInfo = {
                ...listUsersProdider,
                selectOptions: listOptions
            }

        } else {
            listUsersProdiderInfo = listUsersProdider;
        }

        // Точки доставки

        let listLocationsTotal = 0;
        let listLocationsResult = [];

        if(listLocations1 && listLocations1.result){
            listLocationsTotal = listLocationsTotal + listLocations1.result.length;
            listLocationsResult = [...listLocationsResult, ...listLocations1.result];
        }
        if(listLocations2 && listLocations2.result){
            listLocationsTotal = listLocationsTotal + listLocations2.result.length;
            listLocationsResult = [...listLocationsResult, ...listLocations2.result];
        }

        let listLocations = {
            total: listLocationsTotal,
            result: listLocationsResult
        }

        let listLocationsInfo = {};

        if(listLocations && listLocations.result && listLocations.result.length > 0){

            let listOptions = [];

            listLocations.result.map(item => {

                if(listOptions.findIndex(el => el.value == Object.values(item.PROPERTY_431)[0]) < 0){
                    listOptions.push({
                        value: item.PROPERTY_431 && Object.values(item.PROPERTY_431)[0] || '',
                        label: item.NAME
                    })
                }
            })

            listLocationsInfo = {
                ...listLocations,
                selectOptions: listOptions
            }

        } else {
            listLocationsInfo = listLocations;
        }

        yield put(getInitDataSuccess({
            warehouses: warehousesInfo,
            docks: docksInfo,
            time: timeInfo,
            timeList: timeList,
            fieldsWarehouses: fieldsWarehousesInfo, 
            fieldsDocks: fieldsDocksInfo, 
            fieldsBookings: fieldsBookingsInfo,
            equipments: equipmentsInfo,
            typeTrucks: typeTrucksInfo,
            listUsersProdider: listUsersProdiderInfo,
            listLocations: listLocationsInfo,
            listProvider: listProviderInfo
        }));

        // yield put(setActiveWarehouses(activeWarehouses))

    } catch (error) {

        console.log(error);

        yield put(getInitDataFailure(error));

    }
}


export function* getInitDataWatcher() {
    yield takeEvery(getInitData.toString(), getInitDataSaga);
}
