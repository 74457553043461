import {
  appID,
  dockBookingListId,
  domain,
  domainMain,
  fields,
  redirectAuth,
  shipmentList,
  isLocalhost, 
  paramsLocalhostApi, 
  logList,
  fieldsShipment
} from "../../constants/config";

export const getShipmentByIDAndLocationReguest = ({ id, location }) => {

  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": shipmentList,
  }

  if(id){
      params = {
          ...params,
          "FILTER": {}
      }

      if(id){
          params['FILTER'] = {
              ...params["FILTER"],
              "^NAME" : id,
              [fieldsShipment.PROPERTY_627.code]: location
          }
      }
  }

  return new Promise((resolve, reject) => {

      fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': isLocalhost && 'text/plain' || 'application/json'
          },
          body: JSON.stringify(params)
      })
          .then(response => response.json())
          .then(result => {
              if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                  // redirectAuth();
              } else {
                  resolve(result);
              }
          })
          .catch(error => {
              console.log(error);
              reject(error);
          });

  });

}

export const getShipmentReguest = ({ id }) => {
  
  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": shipmentList,
  }

  if(id){
      params = {
          ...params,
          "FILTER": {}
      }

      if(id){
          params['FILTER'] = {
              ...params["FILTER"],
              "ID" : id
          }
      }
  }

  return new Promise((resolve, reject) => {

      fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': isLocalhost && 'text/plain' || 'application/json'
          },
          body: JSON.stringify(params)
      })
          .then(response => response.json())
          .then(result => {
              if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                  // redirectAuth();
              } else {
                  resolve(result);
              }
          })
          .catch(error => {
              console.log(error);
              reject(error);
          });

  });

};

export const updateShipmentReguest = (props) => {
  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": shipmentList,
  }

  if(props){
      params = {
          ...params,
          ...props
      }
  }

  return new Promise((resolve, reject) => {

      fetch("https://"+domainMain+"/api/listedit.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': isLocalhost && 'text/plain' || 'application/json'
          },
          body: JSON.stringify(params)
      })
          .then(response => response.json())
          .then(result => {
              if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                  // redirectAuth();
              } else {
                  resolve(result);
              }
          })
          .catch(error => {
              reject(error);
          });

  });
}

export const updateLogReguest = (props) => {
  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": logList,
  }

  if(props){
      params = {
          ...params,
          ...props
      }
  }

  return new Promise((resolve, reject) => {

      fetch("https://"+domainMain+"/api/listedit.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': isLocalhost && 'text/plain' || 'application/json'
          },
          body: JSON.stringify(params)
      })
          .then(response => response.json())
          .then(result => {
              if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                  // redirectAuth();
              } else {
                  resolve(result);
              }
          })
          .catch(error => {
              reject(error);
          });

  });
}

export const getLogReguest = ({ code }) => {
  
  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": logList,
  }

  //console.log(code);

  if(code){
      params = {
          ...params,
          "ELEMENT_CODE" : [code]
          // "FILTER": {}
      }
  }

  //console.log(params);

  return new Promise((resolve, reject) => {

      fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': isLocalhost && 'text/plain' || 'application/json'
          },
          body: JSON.stringify(params)
      })
          .then(response => response.json())
          .then(result => {
              if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                  // redirectAuth();
              } else {
                  resolve(result);
              }
          })
          .catch(error => {
              console.log(error);
              reject(error);
          });

  });

};

export const addLogReguest = (props) => {
  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": logList,
  };

  if (props) {
    params = {
      ...params,
      ...props,
    };
  }

  return new Promise((resolve, reject) => {
    fetch("https://" + domainMain + "/api/listadd.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': isLocalhost && 'text/plain' || 'application/json'
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((result) => {
        if (
          result == "Refreshed" || result == "Unauthorized" ||
          result.error == "expired_token"
        ) {
          redirectAuth();
        } else {
          resolve(result);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const addCodInfoReguest = (props) => {
  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": shipmentList,
  };

  if (props) {
    params = {
      ...params,
      ...props,
    };
  }

  return new Promise((resolve, reject) => {
    fetch("https://" + domainMain + "/api/listadd.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': isLocalhost && 'text/plain' || 'application/json'
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((result) => {
        if (
          result == "Refreshed" || result == "Unauthorized" ||
          result.error == "expired_token"
        ) {
          redirectAuth();
        } else {
          resolve(result);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const searchFilterReguest = ({ listId, value }) => {
  let params = {
    "IBLOCK_TYPE_ID": "lists",
    "IBLOCK_ID": listId,
  };

  if (value) {
    params = {
      ...params,
      "FILTER": {},
    };

    if (value) {
      params["FILTER"] = {
        ...params["FILTER"],
        "^NAME": value,
      };
    }
  }

  return new Promise((resolve, reject) => {
    fetch("https://" + domainMain + "/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': isLocalhost && 'text/plain' || 'application/json'
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (
          result == "Refreshed" || result == "Unauthorized" ||
          result.error == "expired_token"
        ) {
          redirectAuth();
        } else {
          resolve(result);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getListCodReguest = ({ start, filters, pageSize, initData }) => {

  var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Cookie", "PHPSESSID=9s2nau897654b9trlkv0dko6gd");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(filters),
    redirect: 'follow'
  };

  return new Promise((resolve, reject) => {
    fetch("https://"+domainMain+"/api/cod_render_appointment_data.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result);
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  })



  // let params = {
  //   "IBLOCK_TYPE_ID": "lists",
  //   "IBLOCK_ID": dockBookingListId,
  // };

  // if (filters) {
  //   params = {
  //     ...params,
  //     "FILTER": {},
  //   };
  //   // if(Object.keys(filters) && Object.keys(filters).length > 0){
  //   Object.keys(filters).map((item, index) => {
  //     if (filters[item]) {
  //       let filterItem = {
  //         "^NAME": filters[item][0],
  //       };
  //       if (item == "source_location") {
  //         filterItem = {
  //           [fields["PROPERTY_291"].code]:
  //             Object.values(initData.warehouses).filter((el) =>
  //               el.NAME == filters[item][0]
  //             )[0].ID,
  //         };
  //       }
  //       if (item == "ldd_date") {
  //         let time = filters[item][0].split(" ")[1];
  //         console.log(time);
  //         filterItem = {
  //           [fields["PROPERTY_477"].code]: filters[item][0],
  //           [fields["PROPERTY_665"].code]:
  //             Object.values(initData.time).filter((el) => el.NAME == time)[0]
  //               .ID,
  //         };
  //       }
  //       params = {
  //         ...params,
  //         "FILTER": {
  //           ...params["FILTER"],
  //           ...filterItem,
  //         },
  //       };
  //     }
  //   });
  //   // }
  // }

  // console.log(params);

  // return new Promise((resolve, reject) => {
  //   fetch("https://" + domainMain + "/api/list.php", {
  //     method: "POST",
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(params),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //       if (
  //         result == "Refreshed" || result == "Unauthorized" ||
  //         result.error == "expired_token"
  //       ) {
  //         redirectAuth();
  //       } else {
  //         resolve(result);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       reject(error);
  //     });
  // });
};
