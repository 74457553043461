import { 
    appID, 
    dockBookingListId, 
    domain, 
    domainMain, 
    fields, 
    redirectAuth,
    isLocalhost, 
    paramsLocalhostApi
} from "../../constants/config";

export const getExcelRequest = (data) => {

    return new Promise((resolve, reject) => {

        let urlDefault = "https://"+domainMain+"/api/gen_ulu_dock_report.php";

        if(data.link){
           urlDefault = data.link;
           delete data.link; 
        }

        fetch(urlDefault, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

}

export const searchWarehouseRequest = ({ code, list }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 33,
    }

    if(code || list){
        params = {
            ...params,
            "FILTER": {}
        }

        if(code){
            params['FILTER'] = {
                ...params['FILTER'],
                '^NAME': code
            }
        }

        if(list && list.length > 0){
            params['FILTER'] = {
                ...params['FILTER'],
                NAME: list
            }
        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}

export const searchBookingRequest = ({ code, list }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": dockBookingListId,
        "LOGIC": 'OR'
    }

    if(code || list){
        params = {
            ...params,
            "FILTER": {
                // 'LOGIC': 'OR',
            }
        }

        if(code){
            params['FILTER'] = {
                ...params['FILTER'],
                ['^'+[fields["PROPERTY_341"].code]]: code
            }
        }

        if(list && list.length > 0){
            params['FILTER'] = {
                ...params['FILTER'],
                [fields["PROPERTY_341"].code]: list
            }
        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}

export const searchDestinationRequest = ({ code, list }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 65,
    }

    if(code || list){
        params = {
            ...params,
            "FILTER": {}
        }

        if(code){
            params['FILTER'] = {
                ...params['FILTER'],
                '^PROPERTY_431': code
            }
        }

        if(list && list.length > 0){
            params['FILTER'] = {
                ...params['FILTER'],
                PROPERTY_431: list
            }
        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}